.invoice-container {
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #fff;
	overflow: hidden;
}

.header {
	text-align: center;
	margin-bottom: 20px;
}

.address {
	width: 48%;
	float: left;
}

.address.right {
	float: right;
	text-align: right;
}

.invoice-table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
	overflow: auto;
	pointer: cursor;
}

.invoice-table th,
.invoice-table td {
	padding: 8px;
	border-bottom: 1px solid #ddd;
	text-align: left;
}

.invoice-table th {
	background-color: #f2f2f2;
}

.invoice-total {
	text-align: right;
}

.logo {
	float: left;
	margin-bottom: 20px;
}

.head_1 {
	height: 150px;
}

.detail_info {
	margin-bottom: 25px;
}

.text-right-child {
	text-align: right !important;
	font-size: 1.05em;
}

.no-border {
	border-bottom: 2px solid #f9f9f9;
}
.header-table {
	width: 100%;
}
.table-border {
	border: 1px solid #ccc;
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
	margin-bottom: 20px;
}
.table-border th {
	border: 1px solid #000000;
	padding: 7px;
	background-color: #f2f2f2;
}
.table-border td {
	border: 1px solid #000000;
	padding: 7px;
}

tfoot td {
	background-color: #f2f2f2;
	font-weight: bold;
}

@media print {
	.header-div {
		display: none;
	}
	.invoice-container {
		border: none;
		border-radius: 0;
		background-color: #fff;
		overflow: visible;
		box-shadow: none;
	}
	.invoice-modal {
		box-shadow: none;
		border: none;
		margin: 0 !important;
		padding: 0 !important;
	}
	body {
		margin: 0 !important;
		padding: 0 !important;
	}
	.close-button {
		display: none;
	}
}

.bold-text {
	font-weight: bold;
}
