/* .loader_wrapper {
	justify-content: center;
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 99999999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
	background-color: rgba(217, 217, 217, 0.6);
}

.loader_wrapper.mainLoader {
	background: rgba(0, 0, 0, 0.1);
	z-index: 99999;
} */

.loader_wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader_backdrop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	/* Semi-transparent black */
}

.loader_content {
	z-index: 1;
	/* Ensure the loader content is above the backdrop */
}
