.scroll {
    &::-webkit-scrollbar {
      width: 4px;
      background-color: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #E5E5E5;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #E5E5E5;
    }
}