.loader_wrapper {
	justify-content: center;
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	position: fixed;
	z-index: 1031;
	top: 0;
	left: auto;
	right: 0;
	bottom: 0;
	overflow: hidden;
	background-color: rgba(253, 248, 248, 0.1);
}

.loader_wrapper.mainLoader {
	background: rgba(0, 0, 0, 0.1);
	z-index: 99999;
}
