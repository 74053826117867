@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div[class$='MuiDataGrid-root'] {
  border: 0;
  border-radius: 0;
  div[class$='MuiDataGrid-columnHeaders'] {
    border-bottom: 0;
    border-radius: 0;
    min-height: 35px !important;
    max-height: 35px !important;
}
  .MuiDataGrid-virtualScroller {
    margin-top: 35px !important;
  }
  .MuiDataGrid-virtualScrollerRenderZone {
    .MuiDataGrid-row {
      .MuiDataGrid-cell {
        border-bottom: 1px dashed #BFA054;
        &:focus,
        &:focus-within {
          outline: none !important;
        }
      }
    }
  }
}
